<script>
export default {
  provide() {
    return { parentValidator: this.$validator };
  },
  props: {
    profile: {
      required: true,
      type: Object,
    },
  },
  data() {
    return { empProfile: this.profile };
  },

  computed: {
    validateProfileUpdate() {
      return !this.errors.first('phone number') && !this.errors.first('last name') && !this.errors.first('first name');
    },

    filters() {
      return this.$store.getters['filtersModule/getFilters'];
    },
  },
  methods: {
    async updateProfile() {
      if (!this.validateProfileUpdate) return;
      try {
        this.$vs.loading({ type: 'corners' });
        await this.$store.dispatch('employerModule/patch', this.empProfile);

        this.$vs.notify({
          title: 'Success!',
          text: 'Your profile has been updated.',
        });
        this.$vs.loading.close();
      } catch (error) {
        this.$vs.loading.close();
        this.$vs.notify({
          time: 10000,
          title: 'Something went wrong',
          text: error.message,
          color: 'warning',
        });
      }
    },
  },
};
</script>

<template>
  <vx-card class="border-solid border-2 border-gray-200 mb-5">
    <h2 id="profile-step-1" class="pt-2">Personal Information</h2>
    <vs-divider></vs-divider>
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full lg:mb-3 mb-5">
        <vs-input
          v-model.trim="empProfile.name"
          v-validate="'required'"
          :danger="!!errors.first('first name')"
          :danger-text="errors.first('first name')"
          name="first name"
          data-vv-validate-on="blur"
          icon="person"
          icon-no-border
          class="w-full"
          label="First Name"
        />
      </div>
      <div class="vx-col sm:w-1/2 w-full lg:mb-3 mb-5">
        <vs-input
          v-model.trim="empProfile.surname"
          v-validate="'required'"
          :danger="!!errors.first('last name')"
          :danger-text="errors.first('last name')"
          name="last name"
          data-vv-validate-on="blur"
          icon="person"
          icon-no-border
          class="w-full"
          label="Last Name"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full lg:mb-3 mb-5">
        <vs-input v-model.trim="empProfile.mobile" icon="smartphone" icon-no-border class="w-full" label="Mobile number" />
      </div>
      <div class="vx-col sm:w-1/2 w-full lg:mb-3 mb-5">
        <vs-input
          v-model.trim="empProfile.telephone"
          v-validate="'required|numeric'"
          :danger="!!errors.first('phone number')"
          :danger-text="errors.first('phone number')"
          name="phone number"
          data-vv-validate-on="blur"
          icon="smartphone"
          icon-no-border
          class="w-full"
          label="Phone number"
        />
      </div>
    </div>
    <vs-button
      type="gradient"
      class="mt-2 sm:mt-6 w-full sm:w-1/3 xxl:w-1/5"
      color="#ADCA63"
      gradient-color-secondary="#ADCA63"
      @click="updateProfile()"
      >Update Information</vs-button
    >
  </vx-card>
</template>

<style lang="scss">
* {
  font-family: inherit;
}
</style>
