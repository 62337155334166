<script>
// import upgradeAccount from '../../components/extra-components/profile/upgrade-account.vue';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from '@firebase/storage';

export default {
  components: {
    // upgradeAccount,
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  props: {
    comp: {
      required: true,
      type: Object,
    },
    profile: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      company: this.comp,
      purchaseCredits: false,
      uploadBool: false,
      progressUpload: 0,
      file: File,
      uploadTask: '',
      imgUploader: false,
    };
  },

  computed: {
    freelanceJobs() {
      return this.profile?.freelanceJobPosts;
    },
    permanentJobs() {
      return this.profile?.permanentJobPosts;
    },
  },

  methods: {
    openCreditsModal() {
      if (!this.$store.getters['companiesModule/getComp'] || !this.$store.getters['employerModule/billingDetailsCheck']) {
        this.$store.dispatch('toggleGeneralPopup', {
          active: true,
          title: 'Your profile is incomplete',
          text: 'Please fill in your Company Information and Billing Details before purchasing Job Credits.',
          buttonText: 'Okay',
          click: () => {
            this.$store.dispatch('toggleGeneralPopup', { active: false });
          },
        });
      } else {
        this.$store.dispatch('togglePurchaseCredits', {
          active: true,
          creditType: '',
          title: 'Purchase Job Credits',
          text: 'All jobs posts are active for 30 days. Credits never expire.',
        });
      }
    },
    dropFunc(value) {
      this.company.logo = value.url;
      this.uploadBool = false;
    },
    detectFiles(fileList) {
      if (fileList && fileList.length > 0) {
        this.imgUploader = true;
        Array.from(Array(fileList.length).keys()).map(() => {
          this.upload(fileList[0]);
        });
      }
    },
    async upload(file) {
      const location = 'employers/' + this.profile.employerId + '/company_logo/' + this.profile.employerId + '.jpg';
      const storage = getStorage();
      const storageRef = ref(storage, location);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        'state_changed',
        (sp) => {
          this.progressUpload = Math.floor((sp.bytesTransferred / sp.totalBytes) * 100);
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(ref(storage, uploadTask.snapshot.ref)).then((url) => {
            this.company.logo = url;
            this.$store.dispatch('companiesModule/set', this.company);
            this.imgUploader = false;
          });
        },
      );
    },
  },
};
</script>

<template>
  <vx-card class="p-2 border-solid border-2 border-gray-200">
    <div class="text-center">
      <h4 id="employer-name">{{ profile.name + ' ' + profile.surname }}</h4>
      <!-- <p class="text-grey">{{ profile.email }}</p> -->
    </div>
    <div class="image-upload">
      <input id="file-input" type="file" multiple accept="image/jpeg/png" @change="detectFiles($event.target.files)" />
    </div>

    <div class="avatar-wrp">
      <div id="comp-logo" class="pic-wrp">
        <vs-avatar
          class="mx-auto my-6 block cursor-default"
          icon-pack="feather"
          icon="icon-camera"
          size="120px"
          style="border: 0.3rem solid #fff; box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.3)"
          :src="comp.logo"
        />
        <label for="file-input" class="avatar-edit cursor-pointer">
          <feather-icon
            icon="Edit2Icon"
            class="rounded-full p-2 cursor-pointer"
            svg-classes="w-5 h-5"
            style="color: white; background: #353535"
          />
        </label>
      </div>

      <vs-progress v-if="imgUploader" :height="10" :percent="progressUpload" color="primary" />
    </div>
    <div class="flex justify-center text-center">
      <span>
        <p class="text-xl font-semibold"></p>
        <small class="text-grey"></small>
      </span>
      <span>
        <p class="text-xl font-semibold">Job Credits</p>
        <small class="text-grey"> Freelance: {{ freelanceJobs }}</small>
        <br />
        <small class="text-grey"> Permanent: {{ permanentJobs }}</small>
      </span>
    </div>

    <vs-button id="upgrade-account" class="w-full mt-6 bg-warning" type="border" @click="openCreditsModal">Purchase job credits</vs-button>
  </vx-card>
</template>

<style lang="scss">
* {
  font-family: inherit;
}
</style>
