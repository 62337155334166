<script>
export default {
  name: 'ProfileTour',
  data() {
    return {
      profileTourSteps: [
        {
          target: window.innerWidth > 1199 ? '.vs-sidebar-item-active' : '#employer-name',
          header: {
            title: 'Welcome to <strong>Recruit a Guide</strong>!',
          },
          content: `This is your <strong>Employer Profile</strong>. You need to complete your profile before you can create your first job post.`,
          params: {
            enableScrolling: false,
            placement: 'auto',
          },
        },
        {
          target: '#comp-logo',
          content: `You can upload a company logo here. This image will be what guides see on job posts.`,
          params: {
            enableScrolling: false,
          },
        },

        {
          target: '#upgrade-account',
          content: `<strong>Here you can purchase Job Credits</strong>. You will need to purchase either a Freelance or Permanent Job Credit to create a post job.`,
          params: {
            enableScrolling: false,
          },
        },
      ],
      tourCallbacks: {
        onSkip: this.markTourAsSeen,
        onFinish: this.markTourAsSeen,
      },
    };
  },
  mounted() {
    const employer = this.$store.getters['employerModule/getUser'];
    if (!employer.dashboardTours || !employer.dashboardTours.profileTourSeen) {
      setTimeout(() => {
        try {
          this.$tours['profileTour'].start();
        } catch (error) {
          return;
        }
      }, 1000);
    }
  },
  methods: {
    markTourAsSeen() {
      this.$store.dispatch('employerModule/patch', { dashboardTours: { profileTourSeen: true } });
    },
  },
};
</script>
<template>
  <v-tour name="profileTour" :steps="profileTourSteps" :callbacks="tourCallbacks"></v-tour>
</template>

<style lang="scss">
.v-step,
.v-step__header,
.v-step__arrow,
.v-step__arrow::before {
  background: #adca63 !important;
}
.v-step {
  border-radius: 10px !important;
  z-index: 99999 !important;
}
.v-step__header {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  margin-top: 0 !important;
}

.v-step__button {
  border-radius: 5px !important;
  border-top-width: 0.1rem !important;
  font-size: 1rem !important;
}
</style>
